/* General Settings */
body {
    background-color: #ffffff;
    color: #666666;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 75%;
    margin: 0;
    padding: 0;
}
table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}
table td {
    padding: 0;
    margin: 0;
    vertical-align: top;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    line-height: 150%;
}
th {
    color: inherit;
    background-color: #bbbbbb;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    padding: 2px;
    vertical-align: middle;
}

/* Link Color Settings */
a {
    color: #0000ee;
}
a:visited {
    color: #551a8b;
}
a:hover {
    color: #ff9900;
}

/* Heading Settings */
h1 {
    color: #000000;
    background-color: #ffffff;
}
h2 {
    color: #000000;
    background-color: #ffffff;
}
h3 {
    text-indent: 5px;
    color: #000000;
    background-color: #ffffff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #996699;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: #996699;
}
h4,
h5 {
    text-indent: 2px;
    color: #000000;
    background-color: #ffffff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #996699;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: #996699;
}

/* List Settings */
ul,
ol {
    margin: 5px;
    padding-left: 20px;
}
li {
    margin-left: 2px;
    background-color: inherit;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
}

/* Page Header and Footer */
.header_bar {
    background-image: url(images/back.gif);
    background-repeat: repeat-x;
    height: 105px;
    border-bottom: 1px solid #999999;
    min-width: 670px;
    overflow: hidden;
}
.logo {
    width: 250px;
    float: left;
}
.menu_bar {
    float: right;
    text-align: right;
}
.menu_img {
    float: left;
    text-align: center;
    line-height: 150%;
    font-size: 85%;
}
.menu_img img {
    vertical-align: bottom;
}
.menu_img a {
    text-decoration: none;
}

.footer {
    font-size: 90%;
    line-height: 150%;
    margin: 10px 0 5px 0;
}

/* Main part */
#main {
    min-width: 670px;
}

/* Left Column */
.leftcolumn {
    float: left;
    width: calc(25% - 1px);
    border-right: 1px solid #999999;
}
.leftcolumn .block {
    margin: 10px 5px 1.5em 5px;
}
.leftcolumn .block .blockTitle {
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    color: #333333;
    background-color: #bbbbbb;
    border-bottom: 1px solid #333333;
}
.leftcolumn .block .blockContent {
    background-color: #dddddd;
    color: #666666;
    padding: 5px;
    line-height: 140%;
}

/* Cnter Column Settings */
.centercolumn {
    float: left;
    width: 75%;
    text-align: left;
    line-height: 175%;
    background-color: #ffffff;
    color: inherit;
}
.centercolumn > .block {
    margin: 10px 10px 1.5em 10px;
}
.centercolumn th {
    vertical-align: middle;
    background-color: #bbbbbb;
    color: inherit;
}

#content {
    text-align: left;
    padding: 8px;
}

/* Center - Center */
.centerCcolumn .blockTitle {
    padding: 3px;
    color: #000000;
    font-size: 100%;
    font-weight: bold;
    background-color: #d8bfd8;
}
.centerCcolumn .blockContent {
    padding: 3px;
    line-height: 175%;
    margin-bottom: 2px;
}

/* Center - Left */
.centerLcolumn {
    width: 50%;
    margin-right: 5px;
}
.centerLcolumn .blockTitle {
    padding: 3px;
    color: #333399;
    font-weight: bold;
    margin-top: 0px;
    background-color: #e6e6fa;
}
.centerLcolumn .blockContent {
    padding: 3px 3px 13px;
    line-height: 120%;
}

/* Center - Right */
.centerRcolumn {
    width: 50%;
    margin-left: 5px;
}
.centerRcolumn .blockTitle {
    padding: 3px;
    color: #333399;
    font-weight: bold;
    margin-top: 0px;
    background-color: #e6e6fa;
}
.centerRcolumn .blockContent {
    padding: 3px 3px 13px;
    line-height: 120%;
}

/* Main Menu */
.mainmenu {
    padding: 0;
    margin: 0;
    line-height: 150%;
}
.mainmenu li {
    list-style: none;
}
.mainmenu a {
    font-size: 90%;
    text-decoration: none;
    display: block;
    padding: 0;
    margin: 0;
}
.mainmenu a.menuTop {
    border-bottom: 1px solid #cdcdcd;
    text-indent: 20px;
    background-image: url(images/menu_indent.gif);
    background-repeat: no-repeat;
    background-position: left top;
}
.mainmenu a.menuMain {
    border-bottom: 1px solid #cdcdcd;
    text-indent: 20px;
    background-image: url(images/menu_indent.gif);
    background-repeat: no-repeat;
    background-position: left top;
}
.mainmenu a:hover {
    background-color: #999999;
    color: #ffffff;
    background-image: url(images/menu_indent_hover.gif);
    background-repeat: no-repeat;
    background-position: left top;
}
.mainmenu a.menuSub {
    margin-left: 15px;
    border-bottom: 1px solid #cdcdcd;
    text-indent: 20px;
    background-image: url(images/menu_indent2.gif);
    background-repeat: no-repeat;
    background-position: left top;
}
.mainmenu a.menuSub:hover {
    background-image: url(images/menu_indent2_hover.gif);
    background-repeat: no-repeat;
    background-position: left top;
}

/* for tables */
.outer {
    border: none;
}
.head {
    padding: 5px;
    background-color: #dddddd;
    color: inherit;
}
.even {
    padding: 2px;
}
.odd {
    padding: 2px;
    background-color: #f2f9f9;
    color: inherit;
}
tr.even td {
    padding: 2px;
}
tr.odd td {
    padding: 2px;
    background-color: #f2f9f9;
    color: inherit;
}
.foot {
    padding: 5px;
    background-color: #dddddd;
    color: inherit;
}

/* error message and code */
div.errorMsg {
    color: inherit;
    background-color: #ffcccc;
    text-align: center;
    border-top: 1px solid #ddddff;
    border-left: 1px solid #ddddff;
    border-right: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    font-weight: bold;
    padding: 10px;
}
div.confirmMsg {
    background-color: #ddffdf;
    color: #136c99;
    text-align: center;
    border-top: 1px solid #ddddff;
    border-left: 1px solid #ddddff;
    border-right: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
    font-weight: bold;
    padding: 10px;
}
div.resultMsg {
    background-color: #cccccc;
    color: #333333;
    text-align: center;
    border-top: 1px solid silver;
    border-left: 1px solid silver;
    font-weight: bold;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    padding: 10px;
}
div.xoopsCode {
    color: inherit;
    background: #ffffff url(images/code_back.jpg) no-repeat right top;
    border: 1px inset #333;
    font-family: "Courier New", Courier, monospace;
    padding: 6px 6px 6px 0px;
    overflow: auto;
    width: 640px;
}
div.xoopsQuote {
    color: inherit;
    background: #ffffff url(images/code_back.jpg) no-repeat right top;
    border: 1px inset #333;
    font-family: "Courier New", Courier, monospace;
    padding: 6px 6px 6px 0px;
    overflow: auto;
    width: 640px;
}

/* style for News module */
.item {
    border: 0px solid silver;
}
.itemHead {
    padding: 3px;
    background-color: #ffffff;
    color: #000000;
}
.itemHead a {
    color: #000000;
    background-color: inherit;
}
.itemHead a:hover {
    color: #ff9900;
    background-color: inherit;
}
.itemInfo {
    text-align: right;
    padding: 3px;
    margin-bottom: 5px;
    border-bottom: 1px solid silver;
    font-style: italic;
}
.itemTitle {
    padding: 3px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: large;
    color: #003366;
    background-color: inherit;
}
.itemTitle a {
    padding: 3px;
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: large;
    font-weight: bold;
    color: #003366;
    background-color: inherit;
}
.itemTitle a:hover {
    color: #ff9900;
    background-color: inherit;
}
.itemPoster {
    font-size: 90%;
    font-style: italic;
}
.itemPostDate {
    font-size: 90%;
    font-style: italic;
}
.itemStats {
    font-size: 80%;
}
.itemBody {
    padding: 3px;
}
.itemBody img {
    padding: 5px;
}
.itemTopicImage {
    float: left;
}
.itemText {
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 1.5em;
}
.itemText:first-letter {
    font-size: 133%;
    font-weight: bold;
}
.itemFoot {
    text-align: right;
    padding: 3px;
    margin-top: 5px;
    color: inherit;
    background-color: #efefef;
    border: 1px solid silver;
}
.itemAdminLink {
    font-size: 90%;
}
.itemPermaLink {
    font-size: 90%;
}

/* for comment area */
.comTitle {
    font-weight: bold;
    margin-bottom: 2px;
}
.comText {
    padding: 2px;
}
.comUserStat {
    font-size: 90%;
    color: #2f5376;
    font-weight: bold;
    border: 1px solid silver;
    background-color: #ffffff;
    margin: 2px;
    padding: 2px;
}
.comUserStatCaption {
    font-weight: normal;
}
.comUserStatus {
    margin-left: 2px;
    margin-top: 10px;
    color: #2f5376;
    background-color: inherit;
    font-weight: bold;
    font-size: 10px;
}
.comUserRank {
    margin: 2px;
}
.comUserRankText {
    font-size: 90%;
    font-weight: bold;
}
.comUserRankImg {
    border: 0;
}
.comUserImg {
    margin: 2px;
}
.comDate {
    font-style: normal;
    font-size: 85%;
}
.comDateCaption {
    font-weight: bold;
    font-style: normal;
}
